<template>
  <div :is="featureData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="featureData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Funcionalidade não encontrada
        <b-link class="alert-link" :to="{ name: 'apps-feature-list' }">
          Lista de funcionalidades
        </b-link>
        for other feature.
      </div>
    </b-alert>

    <template v-if="featureData">
      <div>
        <b-card title="Detalhes da Funcionalidade">
          <b-row>
            <b-col md="6">
              <b-form-group label="Nome" label-for="nameFeature">
                <b-form-input v-model="featureData.name" disabled />
                <!-- multiple select -->
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Identificador" label-for="membersFeature">
                <b-form-input v-model="featureData.feature_id" disabled />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Descrição" label-for="nameFeature">
                <b-form-textarea
                  v-model="featureData.description"
                  rows="4"
                  disabled
                />
                <!-- multiple select -->
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-right mt-2 mr-2 mb-2">
                <b-button
                  :to="{
                    name: 'apps-feature-edit',
                    params: { id: featureData.id },
                  }"
                  variant="primary"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
                <b-button
                  v-b-modal.modal-delete-confirmation
                  variant="outline-danger"
                  class="ml-1"
                >
                  {{ $t("message.buttons.delete") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          Você tem certeza que deseja deletar a funcionalidade ?
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-feature-list' }"
            @click="deleteFeature()"
          >
            Deletar
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },

  data() {
    return {
      userData: store.state.user.userData,
    };
  },
  setup() {
    const featureData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}feature/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        featureData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          featureData.value = undefined;
        }
      });

    return {
      featureData,
    };
  },

  methods: {
    deleteFeature() {
      axios({
        method: "delete",
        url: `${URL_API}feature/${this.featureData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `A funcionalidade foi deletada com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            featureData.value = undefined;
          }
        });
    },
  },
};
</script>

<style></style>
